<template>
  <div class="app-container">
    <!-- 搜索栏开始 -->
    <div class="searchHeader">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="流水号">
          <el-input v-model="formInline.tradeId" placeholder="请输入流水号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getSearchData" plain round icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" plain round icon="el-icon-circle-plus" @click="addflowWater">新增流水</el-button>
      </div>
    </div>
    <!-- 搜索栏结束 -->
    <!-- 表格开始 -->
    <el-table
      border
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      :data="tradeData"
      style="width: 100%;"
      height="700"
      :row-class-name="tableRowClassName"
    >
      <el-table-column label="流水号">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="外部订单号">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.outOrderId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="金额(元)">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.total }}</span>
        </template>
      </el-table-column>
      <el-table-column label="手续费(元)">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.feeTotal}}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="交易时间">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.payTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="付款渠道">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.payChannel }}</span>
        </template>
      </el-table-column>
      <el-table-column label="收款渠道">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.settlementChannel }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商户">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.shopId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="服务商">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.projectContractorId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="支付方式">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.payType }}</span>
        </template>
      </el-table-column>
       <el-table-column label="第三方支付号">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.thirdTradeNo }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品描述">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.remark }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.state }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="90" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="warning" plain @click="updateData(scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 表格结束 -->
    <!-- 修改开始 -->
    <el-dialog :title="title"  :visible.sync="centerDialogVisible" width="40%">
      <div class="form">
        <el-form ref="form" :model="form" label-width="100px" style="width:48%">
          <el-form-item label="流水号" prop="id"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input v-model="form.id"></el-input>
          </el-form-item>
          <el-form-item label="外部订单号" prop="outOrderId"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input v-model="form.outOrderId" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="金额(元)" prop="total"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input v-model="form.total"></el-input>
          </el-form-item>
          <el-form-item label="手续费(元)" prop="feeTotal"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input v-model="form.feeTotal"></el-input>
          </el-form-item>
          <el-form-item label="创建时间" prop="createTime"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input v-model="form.createTime"></el-input>
          </el-form-item>
          <el-form-item label="交易时间" prop="payTime"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input v-model="form.payTime"></el-input>
          </el-form-item>
          <el-form-item label="付款渠道" prop="payChannel"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input v-model="form.payChannel"></el-input>
          </el-form-item>
        </el-form>
        <el-form ref="form" :model="form" label-width="90px" style="width:48%">
          <el-form-item label="收款渠道" prop="settlementChannel"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input v-model="form.settlementChannel"></el-input>
          </el-form-item>
          <el-form-item label="商户"  prop="shopId"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input v-model="form.shopId"></el-input>
          </el-form-item>
          <el-form-item label="服务商"  prop="projectContractorId"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input v-model="form.projectContractorId"></el-input>
          </el-form-item>
          <el-form-item label="支付方式" prop="payType"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input v-model="form.payType"></el-input>
          </el-form-item>
          <el-form-item label="商品描述"  prop="remark"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
          <el-form-item label="状态"  prop="state"
            :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]">
            <el-input v-model="form.state"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false" style="margin-right:20px">取 消</el-button>
        <el-popover placement="top" width="160" v-model="visible">
          <p style="color:#EBA549">请再次确定数据是否填写正确？，继续修改？</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="visible = false">取消</el-button>
            <el-button  size="mini" type="text" @click="confirmUpdata" >确定</el-button>
          </div>
          <el-button type="primary"  slot="reference">确 定</el-button>
        </el-popover>
      </span>
    </el-dialog>
    <!-- 修改结束 -->
  </div>
</template>

<script>
import Paging from "../../../components/Paging/Paging.vue";
export default {
  components: {
    Paging
  },
  data() {
    return {
      loading: false,
      formInline: {
        tradeId: "",
        region: ""
      },
      tableDataList: [],
      totalSize: 0,
      tradeData: [],
      form: {},
      centerDialogVisible: false,
      visible:false,
      title:'',
      rules: {
          information: [
            { required: true, message: '不能为空', trigger: 'blur' },
          ],
      }
    };
  },
  methods: {
    //获取表格数据
    getSearchData() {
      const { tradeId } = this.formInline;
      if(tradeId!==''){
        this.loading = true;
        this.$axios.get("/orderTrade/tradeFind", { params: { tradeId } })
        .then(res => {
          this.loading = false;
          if (res.data.state == 1) {
            this.tradeData = [res.data.trade];
            this.$message({showClose: true,message: res.data.msg,type: "success"});
          } else {
            this.$message({showClose: true,message: res.data.msg,type: "error"});
          }
        });
      }
    },
    // 更新
    updateData(row) {
      this.title='修改流水';
      this.form = { ...row };
      this.centerDialogVisible = true;
    },
    // 新增
    addflowWater(){
      this.title='新增流水';
      this.form={
        id:'',outOrderId:'',total:'',feeTotal:'',createTime:'',payTime:'',payChannel:'',
        settlementChannel:'',shopId:'',projectContractorId:'',payType:'',remark:'',state:''};
      this.centerDialogVisible = true;
    },
    // 确定更新
    confirmUpdata() {
      const {id,outOrderId,total,feeTotal,createTime,payTime,payChannel,
      settlementChannel,shopId,projectContractorId,payType,remark,state} = this.form;
      let data = {id,outOrderId,total,feeTotal,createTime,payTime,payChannel,
      settlementChannel,shopId,projectContractorId,payType,remark,state};
      this.visible = false;
      let url=''
      if( this.title==='修改流水'){//修改
        url='/orderTrade/tradeEdit';
      }else{//新增
        url='/orderTrade/tradeCreate';
      }
      if(id!='' && outOrderId!==''&& total!==''&& feeTotal!==''&& createTime!==''
      && payTime!==''&& payChannel!==''&& settlementChannel!==''&& shopId!==''&& 
      projectContractorId!==''&& payType!==''&& remark!==''&& state!==''){
         this.$axios.get(url, { params: data }).then(res => {
          if (res.data.state == 1) {
            this.$message({showClose: true,message: res.data.msg,type: "success"});
          } else {
            this.$message({showClose: true,message: res.data.msg,type: "error"});
          }
          this.centerDialogVisible = false;
        });
      }else{
         this.$message({showClose: true,message: '请填写完整信息',type: 'warning'});
      }
    },
    // 时间转换
    getTiem(params) {
      let unixTimestamp = new Date(params * 1000); //时间戳转换成日期
      // 定义转换返回方式
      Date.prototype.toLocaleString = function() {
        return (
          this.getFullYear() +
          "年" +
          (this.getMonth() + 1) +
          "月" +
          +this.getDate() +
          "日" +
          " " +
          this.getHours() +
          "时" +
          this.getMinutes() +
          "分" +
          this.getSeconds() +
          "秒"
        );
      };
      params = unixTimestamp.toLocaleString();
      return params;
    },
    // 表格颜色
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "success-row";
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.searchHeader {
  display: flex;
  justify-content: space-between;
}
.form {
  display: flex;
  justify-content: space-between;
}
</style>